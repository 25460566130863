.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* requestList Module */
.requests-container {
  padding: 20px;
  font-family: sans-serif;
  margin-top: 20px;
}

.version-label {
  text-align: center;
  margin-top: 40px !important;
  opacity: 0.5;
  font-weight: bold;
}

.logo-image {
  width: 240px;
  float: left;
}

.logo-image-dark {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.setting-icon {
  font-size: 40px;
}

.setting-icon:hover {
  cursor: pointer;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px !important;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.end-column {
  align-items: flex-end;
  margin-right: 32px;
}

.tabs-container {
  font-family: sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
}
.f1063pyq {
  flex-direction: row;
}

.f22iagw {
  display: flex;
}

.f122n59 {
  align-items: center;
}

.f1y7q3j9 {
  overflow-y: visible;
}

.f1mtd64y {
  overflow-x: visible;
}

.f1c21dwh {
  background-color: var(--colorTransparentBackground);
}

.fx3omr {
  padding-bottom: var(--spacingVerticalM);
}

.f1ng84yb {
  padding-left: var(--spacingHorizontalMNudge);
}

.f11gcy0p {
  padding-right: var(--spacingHorizontalMNudge);
}

.f5yzyt {
  padding-top: var(--spacingVerticalM);
}

.f1rjii52 {
  column-gap: var(--spacingHorizontalSNudge);
}

.f4d9j23 {
  -webkit-box-pack: center;
  justify-content: center;
}

.f1cxpek8 {
  text-transform: none;
}

.f1s6fcnf {
  outline-style: none;
}

.f1olsevy {
  grid-template-rows: auto;
}

.frn2hmy {
  grid-template-columns: auto;
}

.f1u07yai {
  grid-auto-flow: column;
}

.f13qh94s {
  display: grid;
}

.f1k6fduh {
  cursor: pointer;
}

.fqdk4by {
  border-bottom-width: 0px;
}

.f1rvrf73 {
  border-left-width: 0px;
}

.f1358rze {
  border-right-width: 0px;
}

.fre7gi1 {
  border-top-width: 0px;
}

.f1q9h2pe {
  -webkit-box-align: stretch;
  align-items: stretch;
}

.f10pi13n {
  position: relative;
}

.flvyvdh {
  flex-wrap: nowrap;
}

.fi64zpg {
  flex-shrink: 0;
}


.victoriousIframe {
  width: 100%;
  height: 200px;
}

/* admin page module */
.menu-item {
  margin: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 12px;
}

.menu-item:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.left-menu {
  /* background-color: #33344a; */
  width: 76px;
  display: flex;
  flex-direction: column;
}

.menu-text {
  font-size: 12px !important;
}

.is-selected {
  background-color: #6266c2;
  color: white !important;
  border-radius: 50%;
}

.page-title {
  font-size: 28px;
}

.main-container {
  display: flex;
  flex-direction: row;
}

.page-area {
  display: flex;
  flex-direction: column;
  width: calc(100% - 74px);
  margin-top: 20px;
}

.edit-form-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: calc(50% - 20px);
}

.title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.main-section {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  width: 100%;
}

/* customactioneditform */
.textArea textarea {
  min-height: 140px !important;
}
/* newrequestform */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 30px;
}

.mr-20 {
  margin-right: 20px !important;
}

.license-label {
  margin-left: 29px;
  font-weight: 400;
  margin-bottom: 8px;
}

.license-checkbox > label {
  font-weight: bold;
}

.email-row {
  padding-top: 18px;
  padding-bottom: 14px;
}

.notification-row {
  padding-bottom: 14px;
}

.valid-icon {
  color: green;
}

.invalid-icon, .warn {
  color: red;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.divider {
  margin-left: -8px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.form-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 10px;
}

.form-section-vertical {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50%;
  margin-right: 15px;
  row-gap: 10px;
}

.checkbox {
  margin-top: 30px;
}

/* sharemailboxesformcontrol */
.mt10 {
  margin-top: 10px;
}

.ml10 {
  margin-left: 10px;
}

.columnFlexDirection {
  flex-direction: column;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controlsGroupContainer {
  width: 95%;
}

.btn {
  opacity: 0.7;
  width: 40px;
}

.removeBtn {
  margin-left: 5px;
}
/* statuspanel */
.status-completed-icon,
.status-not-started-icon,
.status-failed-icon,
.status-pause-icon {
  margin-right: 10px;
}

.status-completed-icon {
  color: green;
}

.status-failed-icon {
  color: red;
}

.status-pause-icon {
  color: orange;
}

.spinner {
  float: left;
  margin-right: 10px;
}

.status-label {
  display: flex;
  align-items: center;
}

.callout {
  width: 320px;
  max-width: 90%;
  padding: 20px 24px;
}

.callout-content {
  overflow: auto;
}


.title {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-icon {
  margin-left: 10px;
}

.info-icon:hover {
  cursor: pointer;
}



/* custom classes */

.f1063pyq {
  flex-direction: row;
}
.f22iagw {
  display: flex;
}
.f1q9h2pe {
  -webkit-box-align: stretch;
  align-items: stretch;
}

.f10pi13n {
  position: relative;
}
.flvyvdh {
  flex-wrap: nowrap;
}
.fi64zpg {
  flex-shrink: 0;
}
.f1063pyq {
  flex-direction: row;
}
.f22iagw {
  display: flex;
}
.f122n59 {
  align-items: center;
}


.f1y7q3j9 {
  overflow-y: visible;
}

.f1mtd64y {
  overflow-x: visible;
}

.f1c21dwh {
  background-color: var(--colorTransparentBackground);
}

.fx3omr {
  padding-bottom: var(--spacingVerticalM);
}

.f1ng84yb {
  padding-left: var(--spacingHorizontalMNudge);
}

.f11gcy0p {
  padding-right: var(--spacingHorizontalMNudge);
}

.f5yzyt {
  padding-top: var(--spacingVerticalM);
}

.f1rjii52 {
  column-gap: var(--spacingHorizontalSNudge);
}

.f4d9j23 {
  -webkit-box-pack: center;
  justify-content: center;
}

.f1cxpek8 {
  text-transform: none;
}

.f1s6fcnf {
  outline-style: none;
}

.f1olsevy {
  grid-template-rows: auto;
}

.frn2hmy {
  grid-template-columns: auto;
}

.f1u07yai {
  grid-auto-flow: column;
}

.f13qh94s {
  display: grid;
}

.f1k6fduh {
  cursor: pointer;
}

.fqdk4by {
  border-bottom-width: 0px;
}

.f1rvrf73 {
  border-left-width: 0px;
}

.f1358rze {
  border-right-width: 0px;
}

.fre7gi1 {
  border-top-width: 0px;
}

.fyu767a {
  border-top-left-radius: var(--borderRadiusMedium);
}

.f1jar5jt {
  border-top-right-radius: var(--borderRadiusMedium);
}

.f16jpd5f {
  border-bottom-left-radius: var(--borderRadiusMedium);
}

.f1aa9q02 {
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.fv1dfc8 {
}

.f1q8l70w {
}

.f61n433 {
}

.fwhevhj {
}

.f122n59 {
  -webkit-box-align: center;
  align-items: center;
}

.f10pi13n {
  position: relative;
}

.fi64zpg {
  flex-shrink: 0;
}

.f1i3iumi {
  line-height: var(--lineHeightBase300);
}

.fk6fouc {
  font-family: var(--fontFamilyBase);
}
.f1i3iumi {
  line-height: var(--lineHeightBase300);
}

.figsok6 {
  font-weight: var(--fontWeightRegular);
}

.fkhj508 {
  font-size: var(--fontSizeBase300);
}

.fk6fouc {
  font-family: var(--fontFamilyBase);
}
.f1063pyq {
  flex-direction: row;
}
.f1o700av {
  text-align: left;
}
.f140knbv {
  margin-bottom: 20px;
}
.f1063pyq {
  flex-direction: row;
}
.f19n0e5 {
  color: var(--colorNeutralForeground1);
}

.f1phragk {
  color: var(--colorNeutralForegroundOnBrand);
}


.f1q5o8ev {
  border-bottom-color: transparent;
}

.f1pdflbu {
  border-left-color: transparent;
}

.f11589ue {
  border-right-color: transparent;
}

.f1p3nwhy {
  border-top-color: transparent;
}

.ffp7eso {
  /* background-color: var(--colorBrandBackground) !important; */
  color: #ffffff !important;

  /* background-color: yellow; */

}
.f122n59 {
  align-items: center;
}

.f12lm4oi {
  margin-left: 20px;
}

.fzaimmi {
  margin-right: 20px;
}

.f22iagw {
  display: flex;
}

.r1572tok {
  box-sizing: border-box;
  color: var(--colorNeutralForeground3);
  display: flex;
}
.fzaimmi {
  margin-right: 20px;
}
.f1i3iumi {
  line-height: var(--lineHeightBase300);
}


.figsok6 {
  font-weight: var(--fontWeightRegular);
}

.fkhj508 {
  font-size: var(--fontSizeBase300);
}

.fk6fouc {
  font-family: var(--fontFamilyBase);
}

.f1o700av {
  text-align: left;
}

.f19n0e5 {
  color: var(--colorNeutralForeground1);
}
.f1k6fduh {
  cursor: pointer;
}
.f1q7ujh::after {
  transform: translateX(var(--fui-Tab__indicator--offset)) scaleX(var(--fui-Tab__indicator--scale));
}

.fug4aj8::after {
  transform-origin: left center;
}

.f1ai4sc1::after {
  transition-timing-function: var(--curveDecelerateMax);
}

.f6zz20j::after {
  transition-duration: var(--durationSlow);
}

.fhwpy7i::after {
  transition-property: transform;
}

.f1oxpfwv::after {
  right: var(--spacingHorizontalM);
}

.fna7m5n::after {
  left: var(--spacingHorizontalM);
}

.f1vx7lu8::after {
  height: var(--strokeWidthThicker);
}

.fo72kxq::after {
  bottom: 0px;
}

.f1ksivud::after {
  background-color: var(--colorCompoundBrandStroke);
}

.ffmd2fr::after {
  z-index: 1;
}

.f1mdlcz9::after {
  position: absolute;
}

.f13zj6fq::after {
  content: "";
}

.f18zvfd9::after {
  border-top-left-radius: var(--borderRadiusCircular);
}

.f1gtfqs9::after {
  border-top-right-radius: var(--borderRadiusCircular);
}

.f14vs0nd::after {
  border-bottom-left-radius: var(--borderRadiusCircular);
}

.fprarqb::after {
  border-bottom-right-radius: var(--borderRadiusCircular);
}
#root{
  width: 100%;
}

:root {
  --borderRadiusNone: 0;
  --borderRadiusSmall: 2px;
  --borderRadiusMedium: 4px;
  --borderRadiusLarge: 6px;
  --borderRadiusXLarge: 8px;
  --borderRadiusCircular: 10000px;
  --fontSizeBase100: 10px;
  --fontSizeBase200: 12px;
  --fontSizeBase300: 14px;
  --fontSizeBase400: 16px;
  --fontSizeBase500: 20px;
  --fontSizeBase600: 24px;
  --fontSizeHero700: 28px;
  --fontSizeHero800: 32px;
  --fontSizeHero900: 40px;
  --fontSizeHero1000: 68px;
  --lineHeightBase100: 14px;
  --lineHeightBase200: 16px;
  --lineHeightBase300: 20px;
  --lineHeightBase400: 22px;
  --lineHeightBase500: 28px;
  --lineHeightBase600: 32px;
  --lineHeightHero700: 36px;
  --lineHeightHero800: 40px;
  --lineHeightHero900: 52px;
  --lineHeightHero1000: 92px;
  --fontFamilyBase: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  --fontFamilyMonospace: Consolas, 'Courier New', Courier, monospace;
  --fontFamilyNumeric: Bahnschrift, 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemibold: 600;
  --fontWeightBold: 700;
  --strokeWidthThin: 1px;
  --strokeWidthThick: 2px;
  --strokeWidthThicker: 3px;
  --strokeWidthThickest: 4px;
  --spacingHorizontalNone: 0;
  --spacingHorizontalXXS: 2px;
  --spacingHorizontalXS: 4px;
  --spacingHorizontalSNudge: 6px;
  --spacingHorizontalS: 8px;
  --spacingHorizontalMNudge: 10px;
  --spacingHorizontalM: 12px;
  --spacingHorizontalL: 16px;
  --spacingHorizontalXL: 20px;
  --spacingHorizontalXXL: 24px;
  --spacingHorizontalXXXL: 32px;
  --spacingVerticalNone: 0;
  --spacingVerticalXXS: 2px;
  --spacingVerticalXS: 4px;
  --spacingVerticalSNudge: 6px;
  --spacingVerticalS: 8px;
  --spacingVerticalMNudge: 10px;
  --spacingVerticalM: 12px;
  --spacingVerticalL: 16px;
  --spacingVerticalXL: 20px;
  --spacingVerticalXXL: 24px;
  --spacingVerticalXXXL: 32px;
  --durationUltraFast: 50ms;
  --durationFaster: 100ms;
  --durationFast: 150ms;
  --durationNormal: 200ms;
  --durationSlow: 300ms;
  --durationSlower: 400ms;
  --durationUltraSlow: 500ms;
  --curveAccelerateMax: cubic-bezier(1,0,1,1);
  --curveAccelerateMid: cubic-bezier(0.7,0,1,0.5);
  --curveAccelerateMin: cubic-bezier(0.8,0,1,1);
  --curveDecelerateMax: cubic-bezier(0,0,0,1);
  --curveDecelerateMid: cubic-bezier(0.1,0.9,0.2,1);
  --curveDecelerateMin: cubic-bezier(0.33,0,0.1,1);
  --curveEasyEaseMax: cubic-bezier(0.8,0,0.1,1);
  --curveEasyEase: cubic-bezier(0.33,0,0.67,1);
  --curveLinear: cubic-bezier(0,0,1,1);
  --colorNeutralForeground1: #242424;
  --colorNeutralForeground1Hover: #242424;
  --colorNeutralForeground1Pressed: #242424;
  --colorNeutralForeground1Selected: #242424;
  --colorNeutralForeground2: #424242;
  --colorNeutralForeground2Hover: #242424;
  --colorNeutralForeground2Pressed: #242424;
  --colorNeutralForeground2Selected: #242424;
  --colorNeutralForeground2BrandHover: #5b5fc7;
  --colorNeutralForeground2BrandPressed: #4f52b2;
  --colorNeutralForeground2BrandSelected: #5b5fc7;
  --colorNeutralForeground3: #616161;
  --colorNeutralForeground3Hover: #424242;
  --colorNeutralForeground3Pressed: #424242;
  --colorNeutralForeground3Selected: #424242;
  --colorNeutralForeground3BrandHover: #5b5fc7;
  --colorNeutralForeground3BrandPressed: #4f52b2;
  --colorNeutralForeground3BrandSelected: #5b5fc7;
  --colorNeutralForeground4: #707070;
  --colorNeutralForegroundDisabled: #bdbdbd;
  --colorNeutralForegroundInvertedDisabled: rgba(255, 255, 255, 0.4);
  --colorBrandForegroundLink: #4f52b2;
  --colorBrandForegroundLinkHover: #444791;
  --colorBrandForegroundLinkPressed: #383966;
  --colorBrandForegroundLinkSelected: #4f52b2;
  --colorNeutralForeground2Link: #424242;
  --colorNeutralForeground2LinkHover: #242424;
  --colorNeutralForeground2LinkPressed: #242424;
  --colorNeutralForeground2LinkSelected: #242424;
  --colorCompoundBrandForeground1: #5b5fc7;
  --colorCompoundBrandForeground1Hover: #4f52b2;
  --colorCompoundBrandForeground1Pressed: #444791;
  --colorBrandForeground1: #5b5fc7;
  --colorBrandForeground2: #4f52b2;
  --colorBrandForeground2Hover: #444791;
  --colorBrandForeground2Pressed: #333357;
  --colorNeutralForeground1Static: #242424;
  --colorNeutralForegroundStaticInverted: #ffffff;
  --colorNeutralForegroundInverted: #ffffff;
  --colorNeutralForegroundInvertedHover: #ffffff;
  --colorNeutralForegroundInvertedPressed: #ffffff;
  --colorNeutralForegroundInvertedSelected: #ffffff;
  --colorNeutralForegroundInverted2: #ffffff;
  --colorNeutralForegroundOnBrand: #ffffff;
  --colorNeutralForegroundInvertedLink: #ffffff;
  --colorNeutralForegroundInvertedLinkHover: #ffffff;
  --colorNeutralForegroundInvertedLinkPressed: #ffffff;
  --colorNeutralForegroundInvertedLinkSelected: #ffffff;
  --colorBrandForegroundInverted: #7f85f5;
  --colorBrandForegroundInvertedHover: #9299f7;
  --colorBrandForegroundInvertedPressed: #7f85f5;
  --colorBrandForegroundOnLight: #5b5fc7;
  --colorBrandForegroundOnLightHover: #4f52b2;
  --colorBrandForegroundOnLightPressed: #3d3e78;
  --colorBrandForegroundOnLightSelected: #444791;
  --colorNeutralBackground1: #ffffff;
  --colorNeutralBackground1Hover: #f5f5f5;
  --colorNeutralBackground1Pressed: #e0e0e0;
  --colorNeutralBackground1Selected: #ebebeb;
  --colorNeutralBackground2: #fafafa;
  --colorNeutralBackground2Hover: #f0f0f0;
  --colorNeutralBackground2Pressed: #dbdbdb;
  --colorNeutralBackground2Selected: #e6e6e6;
  --colorNeutralBackground3: #f5f5f5;
  --colorNeutralBackground3Hover: #ebebeb;
  --colorNeutralBackground3Pressed: #d6d6d6;
  --colorNeutralBackground3Selected: #e0e0e0;
  --colorNeutralBackground4: #f0f0f0;
  --colorNeutralBackground4Hover: #fafafa;
  --colorNeutralBackground4Pressed: #f5f5f5;
  --colorNeutralBackground4Selected: #ffffff;
  --colorNeutralBackground5: #ebebeb;
  --colorNeutralBackground5Hover: #f5f5f5;
  --colorNeutralBackground5Pressed: #f0f0f0;
  --colorNeutralBackground5Selected: #fafafa;
  --colorNeutralBackground6: #e6e6e6;
  --colorNeutralBackgroundInverted: #292929;
  --colorNeutralBackgroundStatic: #333333;
  --colorNeutralBackgroundAlpha: rgba(255, 255, 255, 0.5);
  --colorNeutralBackgroundAlpha2: rgba(255, 255, 255, 0.8);
  --colorSubtleBackground: transparent;
  --colorSubtleBackgroundHover: #f5f5f5;
  --colorSubtleBackgroundPressed: #e0e0e0;
  --colorSubtleBackgroundSelected: #ebebeb;
  --colorSubtleBackgroundLightAlphaHover: rgba(255, 255, 255, 0.7);
  --colorSubtleBackgroundLightAlphaPressed: rgba(255, 255, 255, 0.5);
  --colorSubtleBackgroundLightAlphaSelected: transparent;
  --colorSubtleBackgroundInverted: transparent;
  --colorSubtleBackgroundInvertedHover: rgba(0, 0, 0, 0.1);
  --colorSubtleBackgroundInvertedPressed: rgba(0, 0, 0, 0.3);
  --colorSubtleBackgroundInvertedSelected: rgba(0, 0, 0, 0.2);
  --colorTransparentBackground: transparent;
  --colorTransparentBackgroundHover: transparent;
  --colorTransparentBackgroundPressed: transparent;
  --colorTransparentBackgroundSelected: transparent;
  --colorNeutralBackgroundDisabled: #f0f0f0;
  --colorNeutralBackgroundInvertedDisabled: rgba(255, 255, 255, 0.1);
  --colorNeutralStencil1: #e6e6e6;
  --colorNeutralStencil2: #fafafa;
  --colorNeutralStencil1Alpha: rgba(0, 0, 0, 0.1);
  --colorNeutralStencil2Alpha: rgba(0, 0, 0, 0.05);
  --colorBackgroundOverlay: rgba(0, 0, 0, 0.4);
  --colorScrollbarOverlay: rgba(0, 0, 0, 0.5);
  --colorBrandBackground: #5b5fc7;
  --colorBrandBackgroundHover: #4f52b2;
  --colorBrandBackgroundPressed: #383966;
  --colorBrandBackgroundSelected: #444791;
  --colorCompoundBrandBackground: #5b5fc7;
  --colorCompoundBrandBackgroundHover: #4f52b2;
  --colorCompoundBrandBackgroundPressed: #444791;
  --colorBrandBackgroundStatic: #5b5fc7;
  --colorBrandBackground2: #e8ebfa;
  --colorBrandBackground2Hover: #dce0fa;
  --colorBrandBackground2Pressed: #b6bcfa;
  --colorBrandBackgroundInverted: #ffffff;
  --colorBrandBackgroundInvertedHover: #e8ebfa;
  --colorBrandBackgroundInvertedPressed: #c5cbfa;
  --colorBrandBackgroundInvertedSelected: #dce0fa;
  --colorNeutralStrokeAccessible: #616161;
  --colorNeutralStrokeAccessibleHover: #575757;
  --colorNeutralStrokeAccessiblePressed: #4d4d4d;
  --colorNeutralStrokeAccessibleSelected: #5b5fc7;
  --colorNeutralStroke1: #d1d1d1;
  --colorNeutralStroke1Hover: #c7c7c7;
  --colorNeutralStroke1Pressed: #b3b3b3;
  --colorNeutralStroke1Selected: #bdbdbd;
  --colorNeutralStroke2: #e0e0e0;
  --colorNeutralStroke3: #f0f0f0;
  --colorNeutralStrokeSubtle: #e0e0e0;
  --colorNeutralStrokeOnBrand: #ffffff;
  --colorNeutralStrokeOnBrand2: #ffffff;
  --colorNeutralStrokeOnBrand2Hover: #ffffff;
  --colorNeutralStrokeOnBrand2Pressed: #ffffff;
  --colorNeutralStrokeOnBrand2Selected: #ffffff;
  --colorBrandStroke1: #5b5fc7;
  --colorBrandStroke2: #c5cbfa;
  --colorBrandStroke2Hover: #aab1fa;
  --colorBrandStroke2Pressed: #5b5fc7;
  --colorCompoundBrandStroke: #5b5fc7;
  --colorCompoundBrandStrokeHover: #4f52b2;
  --colorCompoundBrandStrokePressed: #444791;
  --colorNeutralStrokeDisabled: #e0e0e0;
  --colorNeutralStrokeInvertedDisabled: rgba(255, 255, 255, 0.4);
  --colorTransparentStroke: transparent;
  --colorTransparentStrokeInteractive: transparent;
  --colorTransparentStrokeDisabled: transparent;
  --colorNeutralStrokeAlpha: rgba(0, 0, 0, 0.05);
  --colorStrokeFocus1: #ffffff;
  --colorStrokeFocus2: #000000;
  --colorNeutralShadowAmbient: rgba(0, 0, 0, 0.12);
  --colorNeutralShadowKey: rgba(0, 0, 0, 0.14);
  --colorNeutralShadowAmbientLighter: rgba(0, 0, 0, 0.06);
  --colorNeutralShadowKeyLighter: rgba(0, 0, 0, 0.07);
  --colorNeutralShadowAmbientDarker: rgba(0, 0, 0, 0.20);
  --colorNeutralShadowKeyDarker: rgba(0, 0, 0, 0.24);
  --colorBrandShadowAmbient: rgba(0, 0, 0, 0.30);
  --colorBrandShadowKey: rgba(0, 0, 0, 0.25);
  --colorPaletteRedBackground1: #fdf6f6;
  --colorPaletteRedBackground2: #f1bbbc;
  --colorPaletteRedBackground3: #d13438;
  --colorPaletteRedForeground1: #bc2f32;
  --colorPaletteRedForeground2: #751d1f;
  --colorPaletteRedForeground3: #d13438;
  --colorPaletteRedBorderActive: #d13438;
  --colorPaletteRedBorder1: #f1bbbc;
  --colorPaletteRedBorder2: #d13438;
  --colorPaletteGreenBackground1: #f1faf1;
  --colorPaletteGreenBackground2: #9fd89f;
  --colorPaletteGreenBackground3: #107c10;
  --colorPaletteGreenForeground1: #0e700e;
  --colorPaletteGreenForeground2: #094509;
  --colorPaletteGreenForeground3: #107c10;
  --colorPaletteGreenBorderActive: #107c10;
  --colorPaletteGreenBorder1: #9fd89f;
  --colorPaletteGreenBorder2: #107c10;
  --colorPaletteDarkOrangeBackground1: #fdf6f3;
  --colorPaletteDarkOrangeBackground2: #f4bfab;
  --colorPaletteDarkOrangeBackground3: #da3b01;
  --colorPaletteDarkOrangeForeground1: #c43501;
  --colorPaletteDarkOrangeForeground2: #7a2101;
  --colorPaletteDarkOrangeForeground3: #da3b01;
  --colorPaletteDarkOrangeBorderActive: #da3b01;
  --colorPaletteDarkOrangeBorder1: #f4bfab;
  --colorPaletteDarkOrangeBorder2: #da3b01;
  --colorPaletteYellowBackground1: #fffef5;
  --colorPaletteYellowBackground2: #fef7b2;
  --colorPaletteYellowBackground3: #fde300;
  --colorPaletteYellowForeground1: #817400;
  --colorPaletteYellowForeground2: #817400;
  --colorPaletteYellowForeground3: #fde300;
  --colorPaletteYellowBorderActive: #fde300;
  --colorPaletteYellowBorder1: #fef7b2;
  --colorPaletteYellowBorder2: #fde300;
  --colorPaletteBerryBackground1: #fdf5fc;
  --colorPaletteBerryBackground2: #edbbe7;
  --colorPaletteBerryBackground3: #c239b3;
  --colorPaletteBerryForeground1: #af33a1;
  --colorPaletteBerryForeground2: #6d2064;
  --colorPaletteBerryForeground3: #c239b3;
  --colorPaletteBerryBorderActive: #c239b3;
  --colorPaletteBerryBorder1: #edbbe7;
  --colorPaletteBerryBorder2: #c239b3;
  --colorPaletteLightGreenBackground1: #f2fbf2;
  --colorPaletteLightGreenBackground2: #a7e3a5;
  --colorPaletteLightGreenBackground3: #13a10e;
  --colorPaletteLightGreenForeground1: #11910d;
  --colorPaletteLightGreenForeground2: #0b5a08;
  --colorPaletteLightGreenForeground3: #13a10e;
  --colorPaletteLightGreenBorderActive: #13a10e;
  --colorPaletteLightGreenBorder1: #a7e3a5;
  --colorPaletteLightGreenBorder2: #13a10e;
  --colorPaletteMarigoldBackground1: #fefbf4;
  --colorPaletteMarigoldBackground2: #f9e2ae;
  --colorPaletteMarigoldBackground3: #eaa300;
  --colorPaletteMarigoldForeground1: #d39300;
  --colorPaletteMarigoldForeground2: #835b00;
  --colorPaletteMarigoldForeground3: #eaa300;
  --colorPaletteMarigoldBorderActive: #eaa300;
  --colorPaletteMarigoldBorder1: #f9e2ae;
  --colorPaletteMarigoldBorder2: #eaa300;
  --colorPaletteRedForegroundInverted: #dc5e62;
  --colorPaletteGreenForegroundInverted: #359b35;
  --colorPaletteYellowForegroundInverted: #fef7b2;
  --colorPaletteDarkRedBackground2: #d69ca5;
  --colorPaletteDarkRedForeground2: #420610;
  --colorPaletteDarkRedBorderActive: #750b1c;
  --colorPaletteCranberryBackground2: #eeacb2;
  --colorPaletteCranberryForeground2: #6e0811;
  --colorPaletteCranberryBorderActive: #c50f1f;
  --colorPalettePumpkinBackground2: #efc4ad;
  --colorPalettePumpkinForeground2: #712d09;
  --colorPalettePumpkinBorderActive: #ca5010;
  --colorPalettePeachBackground2: #ffddb3;
  --colorPalettePeachForeground2: #8f4e00;
  --colorPalettePeachBorderActive: #ff8c00;
  --colorPaletteGoldBackground2: #ecdfa5;
  --colorPaletteGoldForeground2: #6c5700;
  --colorPaletteGoldBorderActive: #c19c00;
  --colorPaletteBrassBackground2: #e0cea2;
  --colorPaletteBrassForeground2: #553e06;
  --colorPaletteBrassBorderActive: #986f0b;
  --colorPaletteBrownBackground2: #ddc3b0;
  --colorPaletteBrownForeground2: #50301a;
  --colorPaletteBrownBorderActive: #8e562e;
  --colorPaletteForestBackground2: #bdd99b;
  --colorPaletteForestForeground2: #294903;
  --colorPaletteForestBorderActive: #498205;
  --colorPaletteSeafoamBackground2: #a8f0cd;
  --colorPaletteSeafoamForeground2: #00723b;
  --colorPaletteSeafoamBorderActive: #00cc6a;
  --colorPaletteDarkGreenBackground2: #9ad29a;
  --colorPaletteDarkGreenForeground2: #063b06;
  --colorPaletteDarkGreenBorderActive: #0b6a0b;
  --colorPaletteLightTealBackground2: #a6e9ed;
  --colorPaletteLightTealForeground2: #00666d;
  --colorPaletteLightTealBorderActive: #00b7c3;
  --colorPaletteTealBackground2: #9bd9db;
  --colorPaletteTealForeground2: #02494c;
  --colorPaletteTealBorderActive: #038387;
  --colorPaletteSteelBackground2: #94c8d4;
  --colorPaletteSteelForeground2: #00333f;
  --colorPaletteSteelBorderActive: #005b70;
  --colorPaletteBlueBackground2: #a9d3f2;
  --colorPaletteBlueForeground2: #004377;
  --colorPaletteBlueBorderActive: #0078d4;
  --colorPaletteRoyalBlueBackground2: #9abfdc;
  --colorPaletteRoyalBlueForeground2: #002c4e;
  --colorPaletteRoyalBlueBorderActive: #004e8c;
  --colorPaletteCornflowerBackground2: #c8d1fa;
  --colorPaletteCornflowerForeground2: #2c3c85;
  --colorPaletteCornflowerBorderActive: #4f6bed;
  --colorPaletteNavyBackground2: #a3b2e8;
  --colorPaletteNavyForeground2: #001665;
  --colorPaletteNavyBorderActive: #0027b4;
  --colorPaletteLavenderBackground2: #d2ccf8;
  --colorPaletteLavenderForeground2: #3f3682;
  --colorPaletteLavenderBorderActive: #7160e8;
  --colorPalettePurpleBackground2: #c6b1de;
  --colorPalettePurpleForeground2: #341a51;
  --colorPalettePurpleBorderActive: #5c2e91;
  --colorPaletteGrapeBackground2: #d9a7e0;
  --colorPaletteGrapeForeground2: #4c0d55;
  --colorPaletteGrapeBorderActive: #881798;
  --colorPaletteLilacBackground2: #e6bfed;
  --colorPaletteLilacForeground2: #63276d;
  --colorPaletteLilacBorderActive: #b146c2;
  --colorPalettePinkBackground2: #f7c0e3;
  --colorPalettePinkForeground2: #80215d;
  --colorPalettePinkBorderActive: #e43ba6;
  --colorPaletteMagentaBackground2: #eca5d1;
  --colorPaletteMagentaForeground2: #6b0043;
  --colorPaletteMagentaBorderActive: #bf0077;
  --colorPalettePlumBackground2: #d696c0;
  --colorPalettePlumForeground2: #43002b;
  --colorPalettePlumBorderActive: #77004d;
  --colorPaletteBeigeBackground2: #d7d4d4;
  --colorPaletteBeigeForeground2: #444241;
  --colorPaletteBeigeBorderActive: #7a7574;
  --colorPaletteMinkBackground2: #cecccb;
  --colorPaletteMinkForeground2: #343231;
  --colorPaletteMinkBorderActive: #5d5a58;
  --colorPalettePlatinumBackground2: #cdd6d8;
  --colorPalettePlatinumForeground2: #3b4447;
  --colorPalettePlatinumBorderActive: #69797e;
  --colorPaletteAnchorBackground2: #bcc3c7;
  --colorPaletteAnchorForeground2: #202427;
  --colorPaletteAnchorBorderActive: #394146;
  --shadow2: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
  --shadow4: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  --shadow8: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
  --shadow16: 0 0 2px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.14);
  --shadow28: 0 0 8px rgba(0, 0, 0, 0.12), 0 14px 28px rgba(0, 0, 0, 0.14);
  --shadow64: 0 0 8px rgba(0, 0, 0, 0.12), 0 32px 64px rgba(0, 0, 0, 0.14);
  --shadow2Brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 1px 2px rgba(0, 0, 0, 0.25);
  --shadow4Brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 2px 4px rgba(0, 0, 0, 0.25);
  --shadow8Brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 4px 8px rgba(0, 0, 0, 0.25);
  --shadow16Brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 8px 16px rgba(0, 0, 0, 0.25);
  --shadow28Brand: 0 0 8px rgba(0, 0, 0, 0.30), 0 14px 28px rgba(0, 0, 0, 0.25);
  --shadow64Brand: 0 0 8px rgba(0, 0, 0, 0.30), 0 32px 64px rgba(0, 0, 0, 0.25);
}



.resize_handle{
  width: 250px; min-width: 250px; max-width: 250px;

}
.user-grid-button{
  width: 200px; min-width: 200px; max-width: 200px;
}
.coloumn_header{
  width: 50px; min-width: 50px; max-width: 50px;
}
.usergrid_0{
  width: 150px; min-width: 150px; max-width: 150px;
}
.usergrid_1{
  width: 212px; min-width: 212px; max-width: 212px;
}
.usergrid_2{
  width: 200px; min-width: 200px; max-width: 200px;
}
.usergrid_3{
  width: 200px; min-width: 200px; max-width: 200px;
}

.user_info_0{
  width: 50px; min-width: 50px; max-width: 50px;
}
.user_info_1{
  width: 250px; min-width: 250px; max-width: 250px;
}
.user_info_2{
  width: 200px; min-width: 200px; max-width: 200px;
}
.user_info_3{
  width: 200px; min-width: 200px; max-width: 200px;
}
.user_info_4{
width: 150px; min-width: 150px; max-width: 150px;
}
.user_info_5{
width: 212px; min-width: 212px; max-width: 212px;
}
.user_info_6{
width: 200px;
min-width: 200px;
max-width: 200px;
}
.ri7kuyr {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration-line: none;
  vertical-align: middle;
  margin: 0px;
  overflow: hidden;
  background-color: var(--colorNeutralBackground1);
  color: var(--colorNeutralForeground1);
  border: var(--strokeWidthThin) solid var(--colorNeutralStroke1);

  font-family: var(--fontFamilyBase);
  outline-style: none;
  padding: 5px var(--spacingHorizontalM);
  min-width: 96px;
  border-radius: var(--borderRadiusMedium);

  font-size: var(--fontSizeBase300);
  font-weight: var(--fontWeightSemibold);
  line-height: var(--lineHeightBase300);
  transition-duration: var(--durationFaster);
  /* transition-property: background, border, color; */
  transition-timing-function: var(--curveEasyEase);
}

.f4ikngz {
  border-bottom-color: var(--colorNeutralStrokeDisabled);
}

.fy0fskl {
  border-left-color: var(--colorNeutralStrokeDisabled);
}

.f15xbau {
  border-right-color: var(--colorNeutralStrokeDisabled);
}

.f1jj8ep1 {
  border-top-color: var(--colorNeutralStrokeDisabled);
}

.f1bg9a2p {
  background-color: var(--colorNeutralBackgroundDisabled);
}

.f1s2aq7o {
  color: var(--colorNeutralForegroundDisabled);
}

button {
  appearance: auto;
  /* font-style: ;
  font-variant-ligatures: ;
  font-variant-caps: ;
  font-variant-numeric: ;
  font-variant-east-asian: ;
  font-variant-alternates: ;
  font-variant-position: ;
  font-weight: ;
  font-stretch: ;
  font-size: ;
  font-family: ;
  font-optical-sizing: ;
  font-kerning: ;
  font-feature-settings: ;
  font-variation-settings: ; */
  text-rendering: auto;
  /* color: buttontext; */
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  /* background-color: buttonface; */
  margin: 0em;
  padding-block: 1px;
  padding-inline: 6px;
  border-width: 2px;
  border-style: outset;
  /* border-color: buttonborder; */
  border-image: initial;
}



.inputContainer {
  display: flex;
  align-items: center;
}

.inputIcon {
  /* Add your icon styles here */
}

.svgIcon {
  /* Add your SVG styles here */
}

.input {
  /* Add your input styles here */
}

.dataGridContainer {
  margin-top: 1rem;
}

.dataGrid {
  width: 100%;
}

.dummyElement {
  position: fixed;
  height: 1px;
  width: 1px;
  opacity: 0.001;
  z-index: -1;
  content-visibility: hidden;
  top: 0;
  left: 0;
}

.dataGridHeader {
  display: flex;
  flex-direction: column;
}

.dataGridRow {
  display: flex;
  align-items: center;
}

.dataGridHeaderCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerCellButton {
  /* Add your header cell button styles here */
}

.headerCellAside {
  /* Add your header cell aside styles here */
}

.resizeHandle {
  /* Add your resize handle styles here */
}

.sortIcon {
  display: flex;
  align-items: center;
}

.dataGridBody {
  /* Add your data grid body styles here */
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.disabledButton {
  /* Add styles for disabled button */
}

.pageInfo {
  /* Add styles for page info text */
}
.offboard_0{
  position: fixed; height: 1px; width: 1px; opacity: 0.001; z-index: -1; content-visibility: hidden; top: 0px; left: 0px;
}
.offboard_1{
  width: 50px; min-width: 50px; max-width: 50px;
}
.offboard_2{
  width: 250px; min-width: 250px; max-width: 250px;
}
.offboard_3{
  width: 200px; min-width: 200px; max-width: 200px;
}
.offboard_4{
  width: 150px; min-width: 150px; max-width: 150px;
}
.offboard_5{
  width: 122px; min-width: 122px; max-width: 122px;
}
.offboard_6{
  position: fixed; height: 1px; width: 1px; opacity: 0.001; z-index: -1; content-visibility: hidden; top: 0px; left: 0px;
}
.userrow_6{
  width: 50px; min-width: 50px; max-width: 50px;

}

.root-81 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(97, 97, 97);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}
.textField-70 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
}

.root-63 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.requests-container {
  font-family: sans-serif;
  margin-top: 20px;
  padding: 20px;
}

.f1i3iumi {
  line-height: var(--lineHeightBase300);
}

.figsok6 {
  font-weight: var(--fontWeightRegular);
}
.fkhj508 {
  font-size: var(--fontSizeBase300);
}
.fk6fouc {
  font-family: var(--fontFamilyBase);
}
.f1o700av {
  text-align: left;
}
.f19n0e5 {
  color: var(--colorNeutralForeground1);
}

.throbber {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.image-container {
  position: relative;
  width: 600px;
  height: 400px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container .throbber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.f12yfnp8 {
  transition-timing-function: cubic-bezier(0,0,.2,1);
}
.f1lvyeyc {
  transition-duration: 236ms;
}
.fg8on96 {
  transition-property: height;
}
.f6dzj5z {
  max-width: 100%;
}
.f10pi13n {
  position: relative;
}
.f1ewtqcl {
  box-sizing: border-box;
}
.f1immsc2 {
  max-height: 100%;
}
* {
  box-sizing: border-box;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
user agent stylesheet
div {
  display: block;
  unicode-bidi: isolate;
}
.fui-FluentProviderr19d {
  --borderRadiusNone: 0;
  --borderRadiusSmall: 2px;
  --borderRadiusMedium: 4px;
  --borderRadiusLarge: 6px;
  --borderRadiusXLarge: 8px;
  --borderRadiusCircular: 10000px;
  --fontSizeBase100: 10px;
  --fontSizeBase200: 12px;
  --fontSizeBase300: 14px;
  --fontSizeBase400: 16px;
  --fontSizeBase500: 20px;
  --fontSizeBase600: 24px;
  --fontSizeHero700: 28px;
  --fontSizeHero800: 32px;
  --fontSizeHero900: 40px;
  --fontSizeHero1000: 68px;
  --lineHeightBase100: 14px;
  --lineHeightBase200: 16px;
  --lineHeightBase300: 20px;
  --lineHeightBase400: 22px;
  --lineHeightBase500: 28px;
  --lineHeightBase600: 32px;
  --lineHeightHero700: 36px;
  --lineHeightHero800: 40px;
  --lineHeightHero900: 52px;
  --lineHeightHero1000: 92px;
  --fontFamilyBase: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
  --fontFamilyMonospace: 'Cascadia Mono', Consolas, ui-monospace, Menlo, Monaco, monospace;
  --fontFamilyNumeric: Bahnschrift, 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemibold: 600;
  --fontWeightBold: 700;
  --strokeWidthThin: 1px;
  --strokeWidthThick: 2px;
  --strokeWidthThicker: 3px;
  --strokeWidthThickest: 4px;
  --spacingHorizontalNone: 0;
  --spacingHorizontalXXS: 2px;
  --spacingHorizontalXS: 4px;
  --spacingHorizontalSNudge: 6px;
  --spacingHorizontalS: 8px;
  --spacingHorizontalMNudge: 10px;
  --spacingHorizontalM: 12px;
  --spacingHorizontalL: 16px;
  --spacingHorizontalXL: 20px;
  --spacingHorizontalXXL: 24px;
  --spacingHorizontalXXXL: 32px;
  --spacingVerticalNone: 0;
  --spacingVerticalXXS: 2px;
}
.f8497fr {
  line-height: 1.4286;
}
.fk6fouc {
  font-family: var(--fontFamilyBase);
}
.f1o700av {
  text-align: left;
}
.figsok6 {
  font-weight: var(--fontWeightRegular);
}
.fkhj508 {
  font-size: var(--fontSizeBase300);
}
.f19n0e5 {
  color: var(--colorNeutralForeground1);
}
style attribute {
  forced-color-adjust: none;
  background-color: transparent;
}
body {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Web', sans-serif;
  font-size: 1.4rem;
  line-height: 1.4286;
}
body {
  background-color: var(--themeBackgroundColor);
  font-size: 1.4rem;
  overflow: hidden;
  touch-action: none;
}
html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Web', sans-serif;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--themeColor);
}
.theme-defaultV2, .theme-tfl-default {
  --themeBackgroundColor: #F5F5F5;
  --themeColor: #252423;
}
.theme-defaultV2, .theme-tfl-default {
  --themeTitleBarBackgroundColor: #ebebeb;
  --themeTitleBarColor: #616161;
  --themeTitleBarButtonHoverBackgroundColor: rgba(255,255,255,0.65);
  --themeTitleBarButtonHoverColor: #616161;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
html {
  font-size: 10px;
  forced-color-adjust: none;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}
.r1ugpin8 ::-webkit-scrollbar {
  height: 1.6rem;
  width: 1.6rem;
}
.r1ugpin8 ::-webkit-scrollbar-corner {
  background-color: transparent;
}
.r1ugpin8 ::-webkit-scrollbar-thumb {
  border-bottom-right-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  border-top-left-radius: 0.9rem;
  border-top-width: 0.4rem;
  border-right-width: 0.4rem;
  border-bottom-width: 0.4rem;
  border-left-width: 0.4rem;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  background-clip: content-box;
  background-color: var(--colorScrollbarOverlay);
}

.app-layout-area--sub-nav_0{
  grid-area: sub-nav;
}

.app-layout-area--sub-nav_1{
  position: relative;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  background-color: #f3f2f1;
}

.app-layout {
  display: flex;
  width: 100%;
  height: 100%;
}

.sidebar {
  width: 60px;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px 0; */
  border-right: 1px solid #d1d1d1;
}

.sidebar-item {
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding: 10px 10px;
  width: 100%;
  height: 54px;
   
}

/* .sidebar-item.active,
.sidebar-item:hover {
  background-color: #ffffff;
} */
.sidebar-item-included.active,
.sidebar-item-included:hover {
  background-color: #ffffff;
}

.sidebar-item .icon {
  font-size: 24px;
}

.app-layout-area {
  flex-grow: 1;
  background-color: #f5f5f5;
  max-width: 240px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slot-measurer {
  padding: 20px;
}

.channel-list-container {
  display: flex;
  flex-direction: column;
}

.channel-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-list-header h1 {
  font-size: 24px;
  margin: 0;
}

.channel-list-options button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.tree {
  margin-top: 20px;
}

.tree-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.tree-item .tree-title {
  display: flex;
  align-items: center;
  font-size: 8pt;
}

.tree-item .avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.tree-item .more-options {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: auto;
}

.icon {
  font-size: 20px;
}
.sidebar a {
  text-decoration: none;
}

.notification-activity {

  font-size: 7.5pt;
  background-color: red;
  height: 15px;
  width: 15px;
  /* padding: 9px; */
  border-radius: 7.5px;
  display: block;
  position: absolute;
  margin-left: 21px;
  margin-top: -16px;
  z-index: 100;
  text-align: center;
  margin-bottom: 20p;
  
  }
  

.css-263 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(98, 100, 167);
  display: block;
  background-color: rgb(244, 244, 252);
  border-color: rgb(244, 244, 252);
  border-style: solid;
  border-width: 1px;
  padding: 1px 0.625em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.css-326 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  display: block;
  border-style: solid;
  border-width: 1px;
  padding: 1px 0.625em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.model_approver_0{
  color: rgb(98, 100, 167)
}
.model_approver_1{
  color: rgb(37, 36, 36);
}
.model_approver_2{
  font-family: FabricMDL2Icons;
}
.model_approver_3{
  width: 36px; height: 36px;
}
.model_approver_4{
  color: rgb(37, 36, 36)
}
.model_approver_5{
  color: rgb(37, 36, 36)
}
.model_approver_6{
  color: rgb(37, 36, 36);
}
.model_approver_7{
  background-color: rgb(244, 244, 252); fill: rgb(98, 100, 167); width: 32px; height: 32px; border-radius: 50%;
}
.model_approver_8{
  color: rgb(37, 36, 36);
}
.model_approver_9{
  color: rgb(37, 36, 36);
}
.model_approver_10{
  color: rgb(37, 36, 36);
}
.css-267 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(196, 49, 75);
  display: block;
  background-color: rgb(252, 244, 246);
  border-color: rgb(252, 244, 246);
  border-style: solid;
  border-width: 1px;
  padding: 1px 0.625em;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Popup css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 500px;

  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-content {
  margin-top: 20px;
}
.user-info_0{
font-family: FabricMDL2Icons;
}

.user_info_1{
width: 36px; height: 36px;
}

.user-info-2{
color: rgb(37, 36, 36);
}
.user-info-3{
  color: rgb(98, 100, 167);
}
.user-info-4{
  background-color: rgb(244, 244, 252); fill: rgb(98, 100, 167); width: 32px; height: 32px; border-radius: 50%;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ms-Label {
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}

.ms-TextField {
  margin-bottom: 15px;
}

.ms-TextField-wrapper {
  position: relative;
}

.ms-TextField-fieldGroup {
  border: 1px solid #ccc;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.ms-TextField-field {
  border: none;
  outline: none;
  flex: 1;
  padding: 5px;
  font-size: 14px;
}

.ms-Dropdown-container {
  margin-bottom: 15px;
}

.ms-Dropdown {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 5px;
}

.ms-Dropdown.is-disabled {
  background-color: #f4f4f4;
  cursor: not-allowed;
}

.ms-Dropdown-title {
  flex: 1;
}

.ms-Dropdown-caretDownWrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.ms-Dropdown-caretDown {
  font-size: 12px;
}

.ms-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0078d4; */
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
}

.ms-Button:disabled {
  background-color: #f4f4f4;
  color: #a6a6a6;
  cursor: not-allowed;
}

.ms-Button-icon {
  margin-right: 5px;
}

.ms-MessageBar {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 2px;
  margin-top: 15px;
}

.ms-MessageBar.root-isError {
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  color: #842029;
}

.ms-MessageBar-content {
  display: flex;
  align-items: center;
}

.ms-MessageBar-icon {
  margin-right: 10px;
}

.ms-Panel-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #ccc;
}

.ms-Panel-footerInner {
  display: flex;
}
.root-49 {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-family: FabricMDL2Icons;
}
.newUserStatus {
  font-weight: bold;
  margin-bottom: 20px;
}

.status-item {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
  margin-right: 13px !important;
  }
.activity-sidebar{
  position: absolute;
}
.status-spinner {
  margin-right: 15px !important;
}

.status-item .status-spinner,
.status-item .status-static-icon,
.status-item .status-completed-icon {
  margin-right: 10px;
  font-size: 16px;
}
status-completed-icon {

}

.calender-container{
  filter:blur(10px)
}

.status-static-icon {
  color: gray; /* You can customize the color */
}

.status-completed-icon {
  color: green; /* Green color for completed status */
}
.teams-application-pane {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  flex-basis: 0;
  overflow: scroll;
  background-color: #fff;
}
#team-header-title{
  font-weight: bold;
  font-size: 18px;
}
.onboarder-controls{
  width: 100%;
  /* border: 1px solid black; */
  /* margin-top: 20px; */
  overflow: hidden; /* add this to contain floated children */
}
.searchbox-onboarder{
  width: 320px;
}
.button-onboarder {
  width: 120px;
  float:left; /* add this */
  /* border: 1px solid red; */
  height: 70px;

  padding: 9px; padding-top: 20px;
}
.searchbox-onboarder {
  /* border: 1px solid green; */
  float: left; /* add this */
  height: 70px;

}
.button_pivot{
  width: 406px
}

.css-106.ms-Pivot{
  width: 400px;
}
.onboarder-controls{
  margin-top: 20px;
}
.onboarder-controls button {
  float: left;
  /* background-color: #5b5fc6; */
}
.onboarder-user-screen {
  background-color: #5b5fc6;

}

.onboarder-controls .ms-SearchBox{
  float: left;
  width: 300px;
border-radius: 4px;
background-color: #fff;
}
.pagination-controls{
  margin-top: 10px;
}
.pagination-controls .ms-Button{
  margin-right: 0px;
}
.activity-sidebar span{
  margin-right: 5px;
}
.root-117{
  background-color: var(--colorBrandBackground);
}

.teams-tabs {
  background-color: #fafafa;
  }

  /* .not-included-tooltip {
    display: none;
  } */
  .not-included-tooltip {
    display: none;
  }
  #e5e5e5
  .not-included-sidebar-item:hover{
    background-color: yellow !important;
  }
  /* .not-included-sidebar-item:hover + .not-included-tooltip {
    display: block;
    position: absolute;
    top: 10px;
    left: 50px;
    color: white;
    text-align: center;
    background-color: rgb(138, 80, 251);
    opacity: 1;
    width: 200px;
    height: 58px;
    } */
  .onboarder-controls button{
    /* background-color: #5b5fc7; */
    border-radius: 4px;
  }
  .requests-container button {
    /* background-color: #5b5fc7; */
    border-radius: 4px;
  }
  .requests-container .page-bottom-buttons button {
    background-color: #5b5fc7;
    border-radius: 4px;
    margin-top: 14px;
    margin-right: 14px;
    color: #fff
    
  }
  .requests-container .page-bottom-buttons button:hover {
    background-color: rgb(237, 235, 233);
    border-radius: 4px;
    color: #000000
    
  }
  .header-row button{
    /* background-color: rgba(24, 100, 171, 0.0) !important; */
    
  }

  .offboarder-controls button {
    color: #000000;
  }
  .ms-Button {
    color: #000000
  }

  .onboarder-onboarding button {
    color: #ffffff !important;
  }
  .onboarder-user-screen:hover {
    color: #000000 !important;
  }
  

  .root-103:hover{
    color: #000000 !important;
  }
  .pagenator-textfeild{
    margin-left: 20px;
    margin-right: 20px;

  }
  .step-3-details {
    position: relative;
    /* border-top: 30px; */
    top: 54px;
    text-align: center;
    right: 13px;
    padding: 16px;
    width: 209px;
    border-radius: 2px;
    height: 80px;
    background-color: red;
    }
    .arrow {
      /* border-radius: 4px; */
      width: 199px;
      height: 20px;
      /* background-color: black; */
      color: #fff;
      position: relative;
      animation: moveInOut 2s infinite;
      background-color: red;
      left: 39px;
      top: -12px;
      text-align: left;
      padding: 2px;
      font-size: 10pt;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      }
      

  .arrow::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid red;
    /* background-color: red; */

    position: absolute;
    left: -20px; /* Position the arrowhead */
    top: -10px; /* Center the arrowhead vertically */
  }
   .step-3-arrow {
    left: 95px !important;
    top: 195px !important;
    width: 180px !important;
   }

   .step-3-arrow::after {
      left: 180px !important;
   }

   .step-4-arrow {
    /* border-bottom: 311px; */
    padding: 0px;
    z-index: 9999999;
    position: fixed;
    position: fixed;
    /* width: 30px; */
    /* height: 30px; */
    width: 141px;
    background: red;
    left: calc(50vw + 223px);
    top: calc(50vh + 235px);
    transform: translateX(-50%);
    }
   .step-5-arrow {
    left: 3px !important;
    top: -40px !important;
    padding: 0px;
    position: relative;
    width: 85px;
    }
    .step-5-1-arrow {
      width: 171px !important;
      left: 7px !important;
      top: 10px !important;
      margin-left: 30px;
      margin-top: 11px;
      padding: 0px;
      position: initial;
      }
      .step-5-2-arrow {
        left: 124px !important;
        top: 315px !important;
        width: 163px  !important;
        padding: 0px;
        text-align: right !important;
        }
      .step-5-2-arrow::after {
        left: 163px !important

      }

        
        .step-6-arrow::after {
          left: 180px !important
          /* lef
      
    .step-6-arrow {
      left: 47px !important;
      top: 427px !important;
      width: 180px  !important;
      padding: 0px;
      text-align: right !important;
      }
      .step-6-arrow::after {
        left: 180px !important
        /* left: 246px !important Position the arrowhead */
      }
      .step-9-arrow::after {
        left: 125px !important
        /* left: 246px !important Position the arrowhead */
      }
      .step-9-arrow{
        left: 165px !important;
        top: 313px !important;
        /* margin-left: 30px; */
        /* margin-top: -23px; */
        width: 125px !important;
        padding: 1px;
        position: absolute;
      }
      .step-91-arrow::after {
        left: 165px !important
        /* left: 246px !important Position the arrowhead */
      }
      .step-91-arrow{
        left: 125px !important;
        top: 430px !important;
        /* margin-left: 30px; */
        /* margin-top: -23px; */
        width: 165px !important;
        padding: 1px;
        position: absolute;
      }
      .step-10-arrow{
        /* left: 793px !important; */
        top: 249px !important;
        width: 155px;
        margin-left: 450px;
        margin-top: -74px;
        /* width: 150px !important; */
        /* height: 150px !important; */
        /* margin-left: 30px; */
        /* margin-top: -23px; */
        padding: 0px;
        position: initial;
        float: left;
        
      }
      .step-10-arrow-userselect{
        /* left: 793px !important; */
        width: 81px !important;
        top: 249px !important;
        margin-left: 450px;
        margin-top: -27px;
        /* width: 150px !important; */
        /* height: 150px !important; */
        /* margin-left: 30px; */
        /* margin-top: -23px; */
        padding: 0px;
        position: initial;
        float: left;
        
      }
      @media screen and (min-width: 1150px) {
        .step-10-arrow {
          margin-top: -57px;
        }
      }

.step-10-101-arrow {
    /* Position and sizing from step-101-arrow */
    position: absolute !important;
    z-index: 9999;
    /* margin-right: 33vw !important; */
    /* margin-top: 84vh; */
    width: 250px !important;
    height: 150px !important;
    /* margin-right: 30px !important; */
    /* right: 50px !important; */
    /* Additional styles from step-10-arrow */
    padding: 0px;
    float: left;

    /* Overriding certain styles */
    /* top: 249px !important; If top positioning needs to be preserved */
    /* margin-left: 450px; This will override 82vw if uncommented */
    /* margin-top: -27px; This will override 75vh if uncommented */
    position: initial; /* This will reset absolute positioning, but seems contradictory. Remove if not needed */
}
.bottom-anchored-arrow::after{
  left: 161px !important;
}
.bottom-anchored-arrow{
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  position: fixed !important;
  z-index: 99999999;
  bottom: 53px !important;
  left: 120px;
  width: 161px !important;
  display: block;
  margin-top: calc(100vh - 116px);
  padding-left: 0;
}
.step-10-101-arrow::before {
    display: none;
    bottom: 249px !important;
    left: 300px !important; /* Position the arrowhead */
}
.step-1and7-arrow {
  width: 255px !important;
  padding: 1px;
}
.step-4-0-arrow{
  left: 125px !important;
  top: 430px !important;
  /* margin-left: 30px; */
  /* margin-top: -23px; */
  width: 165px !important;
  padding: 1px;
  position: absolute;
}
.step-4-0-arrow::before {
  display: none;
  bottom: 249px !important;
  left: 165px !important; /* Position the arrowhead */
}
.step-4-0-arrow {
  left: 124px !important;
  top: 425px !important;
  width: 167px  !important;
  padding: 0px;
  text-align: right !important;
  }
.step-4-0-arrow::after {
  left: 167px !important

}
  

.step-4-1-status-arrow{
  left: 125px !important;
  top: 430px !important;
  /* margin-left: 30px; */
  /* margin-top: -23px; */
  width: 165px !important;
  padding: 1px;
  position: absolute;
}
.step-4-1-status-arrow::before {
  display: none;
  bottom: 249px !important;
  left: 165px !important; /* Position the arrowhead */
}
.step-4-1-userinfo-arrow{
  right: 142px !important;
  top: 132px !important;
  /* margin-left: 30px; */
  /* margin-top: -23px; */
  width: 120px !important;
  padding: 1px;
  position: absolute;
}
.step-4-1-userinfo-arrow::before {
  /* display: none; */
  bottom: 249px !important;
  left: 205px !important; /* Position the arrowhead */
}
.arrow-right {
  /* padding: 5px; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
  width: 100px;
  height: 20px;
  position: absolute; /* Absolute positioning */
  animation: moveInOut 2s infinite;
  background-color: red;
  z-index: 9999; /* Ensure this is higher than .app-layout-area */
  top: 50px; /* Adjust as needed */
  /* right: 10px; Adjust as needed */
  text-align: right;
  padding-left: 5px;
  font-size: 10pt;
}
  
      @keyframes moveInOut {
        0%, 100% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-10px);
        }
      }
  .arrow-right::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid red;;
    position: absolute;

    left: 120px; /* Position the arrowhead */
    top: -10px; /* Center the arrowhead vertically */
  }

  @keyframes moveInOut {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .end-column{
    
  }

  .teams-tab-image{
    max-height: 100%;
max-width: 100%;
  }
  .user-offboard-buttons span{
    margin-left: auto;
    margin-right: auto;
  }
  .teams-menu-tab{
    padding: 0px !important;
padding-bottom: 20px !important;
padding-top: 20px !important;
  }
  .teams-menu-tab:after{
    width: 61px !important;
    left: 0px !important;  }

.sidebar-icon-svg{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.sidebar-title-text{
  color: var(--colorNeutralForeground3);
text-align: center;
font-size: 8pt;
/* width: 45px; */
display: block;
/* margin: 10px 10px 0 0; */
padding: 0px auto;
padding: auto;
margin-left: auto;
margin-right: auto;
}

.teams-channels-sidebar{
  padding-left: 42px;
  padding-bottom: 5px;
  font-size: 8pt;
}
/* .user-licance-info li{
  list-style-type: ;
} */

.user-licence-header{
  padding-bottom: 100px;
}

.is-selected {
  background-color: #6266c2;
  color: white !important;
  border-radius: 0 !important;
  }
  

  .user-requested-approver span {
    margin-left: auto !important ;
  }

  .modal-approver-controls button {
    background-color: #5b5fc5;
    color: #fff;
    border-radius: 4px;
  }

  .modal-approver-controls button:hover {
    background-color: rgb(237, 235, 233);
    color: #000;
    border-radius: 4px;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 99999;
    color: white;
}

.modal__content {
  z-index: 9999999999999;
  width: 50vw;
  height: 50vh;
  border: 1px solid black;
  background-color: black;
  }

.ms-ComboBox-CaretDown-button {
  right: -10px;
}